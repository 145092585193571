import { useRef, useState } from "react";
import { Link, useNavigate, Navigate, useSearchParams } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext.js";
import Alert from "../ui/Alert.js";
import Input from "../ui/Input.js";
import ChoosePasswordInput from "./ChoosePasswordInput.js";
import GoogleIcon from "../icons/GoogleIcon.js";
import JsongenLogo from "../icons/JsongenLogo.js";
import { testEmail } from "../../utils/regex.js";

const AUTH_FORM_CONSTANTS = {
  SIGNIN: {
    titleText: "Sign in to your account",
    emailActionText: "Sign in",
    noActionText: "Not a member?",
    linkTo: "/signup",
    linkToText: "Create your account.",
  },
  SIGNUP: {
    titleText: "Create your account",
    emailActionText: "Sign up",
    noActionText: "Already have an account?",
    linkTo: "/signin",
    linkToText: "Sign in now.",
  },
};

export default function AuthForm({ type, handleFormMessage }) {
  const [searchParams] = useSearchParams();
  const redirect = searchParams.get("redirect");

  const [alertMessage, setAlertMessage] = useState("");
  const [isValidSignUpPassword, setIsValidSignUpPassword] = useState(false);

  const emailRef = useRef();
  const passwordRef = useRef();

  const navigate = useNavigate();

  const { session, signIn, signInWithOAuth, signUp } = useAuth();

  if (!redirect && session) {
    return <Navigate to="/dashboard" replace />;
  }

  const handleSignInAction = async (e) => {
    e.preventDefault();
    const email = emailRef.current?.value;
    const password = passwordRef.current?.value;

    if (!testEmail(email)) {
      setAlertMessage("Please provide a valid email address.");
      return;
    }

    if (!email || !password) {
      setAlertMessage("Please fill in all required fields.");
      return;
    }

    const { data, error } = await signIn({
      email: email,
      password: password,
    });

    if (!data || error) {
      setAlertMessage("Incorrect email or password.");
      return;
    }

    if (data.user?.role === "authenticated") {
      navigate("/dashboard");
    } else {
      console.error(error);
      setAlertMessage("Something went wrong. Please try again or reset your password.");
    }
  };

  const handleSignUpAction = async (e) => {
    e.preventDefault();

    const email = emailRef.current?.value;
    const password = passwordRef.current?.value;

    if (!testEmail(email)) {
      setAlertMessage("Please provide a valid email address.");
      return;
    }

    if (!email || !password) {
      setAlertMessage("Please fill in all required fields.");
      return;
    }

    const { data, error } = await signUp({
      email: email,
      password: password,
      options: {
        emailRedirectTo: `${process.env.REACT_APP_CLIENT_BASE_URL}/signin?redirect=true`,
      },
    });

    if (!error) {
      handleFormMessage(data.user.email);
    } else {
      setAlertMessage("Something went wrong. Please try again.");
    }
  };

  const disableActionButton = type === "SIGNUP" && !isValidSignUpPassword;

  return (
    <>
      <div className="flex min-h-full flex-1 flex-col justify-center sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <JsongenLogo textColor="text-indigo-700" braceColor="text-gray-600" />
          <h2 className="mt-2 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            {AUTH_FORM_CONSTANTS[type].titleText}
          </h2>
        </div>

        <div className="mt-2 sm:mx-auto sm:w-full sm:max-w-[480px]">
          <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
            {alertMessage && <Alert message={alertMessage} />}
            <form className="space-y-6">
              <Input
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                required
                onChange={() => setAlertMessage(undefined)}
                ref={emailRef}
                labelClasses="flex text-sm font-medium leading-6 text-gray-900"
                inputClasses="block w-full mt-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              >
                Email address
              </Input>

              {type === "SIGNIN" ? (
                <Input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  onChange={() => setAlertMessage(undefined)}
                  ref={passwordRef}
                  helperLinkTo={"/forgot-password"}
                  helperLinkText={"Forgot password?"}
                  labelClasses="flex text-sm font-medium leading-6 text-gray-900"
                  inputClasses="block w-full mt-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                >
                  Password
                </Input>
              ) : (
                <ChoosePasswordInput
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  updateValidState={setIsValidSignUpPassword}
                  ref={passwordRef}
                  labelClasses="flex text-sm font-medium leading-6 text-gray-900"
                  inputClasses="block w-full mt-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                >
                  Password
                </ChoosePasswordInput>
              )}

              <button
                disabled={disableActionButton}
                onClick={type === "SIGNUP" ? handleSignUpAction : handleSignInAction}
                className={`flex w-full justify-center rounded-md px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-indigo-600 ${
                  disableActionButton
                    ? "bg-indigo-200 cursor-not-allowed"
                    : "bg-indigo-600 hover:bg-indigo-500"
                }`}
              >
                {AUTH_FORM_CONSTANTS[type].emailActionText}
              </button>
            </form>

            <div>
              <div className="relative mt-6">
                <div className="absolute inset-0 flex items-center" aria-hidden="true">
                  <div className="w-full border-t border-gray-200" />
                </div>
                <div className="relative flex justify-center text-sm font-medium leading-6">
                  <span className="bg-white px-6 text-gray-900">Or continue with</span>
                </div>
              </div>

              <div className="mt-6 ">
                <button
                  className="flex w-full items-center justify-center gap-3 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:ring-transparent"
                  onClick={signInWithOAuth}
                >
                  <GoogleIcon />
                  <span className="text-sm font-semibold leading-6">Google</span>
                </button>
              </div>
            </div>
          </div>

          <p className="mt-5 text-center text-sm text-gray-500">
            {AUTH_FORM_CONSTANTS[type].noActionText + " "}
            <Link
              onClick={() => setAlertMessage(undefined)}
              to={AUTH_FORM_CONSTANTS[type].linkTo}
              className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500"
            >
              {AUTH_FORM_CONSTANTS[type].linkToText}
            </Link>
          </p>
        </div>
      </div>
    </>
  );
}
