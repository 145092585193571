import { BracesIcon, FilePlusIcon, ShieldCheckIcon, ChartSplineIcon } from "lucide-react";

const features = [
  {
    name: "Custom Output with JSON Precision",
    description:
      "Your data, your rules. Define the structure of your API response with precision using our JSON schema definitions.",
    icon: BracesIcon,
  },
  {
    name: "Effortless Integration with File Input Flexibility",
    description:
      "Leverage the versatility of our API to process various file types. Upload images, PDFs, CSVs, and more.",
    icon: FilePlusIcon,
  },
  {
    name: "Privacy-First Data Handling",
    description:
      "Neither OpenAI nor we use your data for training purposes. Your inputs are processed while ensuring your sensitive information remains confidential and secure.",
    icon: ShieldCheckIcon,
  },
  {
    name: "Streamlined Performance and Scalability",
    description:
      "Enjoy high efficiency with our API, built to handle your requests seamlessly and scale effortlessly with your business needs.",
    icon: ChartSplineIcon,
  },
];

export default function FeatureSection() {
  return (
    <>
      <div className="mx-auto max-w-2xl lg:text-center">
        <h2 className="text-base font-semibold leading-7 text-indigo-600">
          Adapting to your needs
        </h2>
        <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          AI-Driven Data Structuring
        </p>
        <p className="mt-6 text-lg leading-8 text-gray-600">
          Unlock the power of AI for seamless JSON data generation and extraction. Our API
          interprets your prompts and file inputs to deliver structured outputs, all customized to
          fit your specific requirements.
        </p>
      </div>
      <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl text-left">
        <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
          {features.map((feature) => (
            <div key={feature.name} className="relative pl-16">
              <dt className="text-base font-semibold leading-7 text-gray-900">
                <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-600">
                  <feature.icon className="h-6 w-6 text-white" aria-hidden="true" />
                </div>
                {feature.name}
              </dt>
              <dd className="mt-2 text-base leading-7 text-gray-600">{feature.description}</dd>
            </div>
          ))}
        </dl>
      </div>
    </>
  );
}
