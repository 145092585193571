export default function JsongenLogo({ className, small = false, textColor, braceColor }) {
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  return (
    <a href="/" className={classNames("-m-1.5 p-1.5", className)}>
      {small ? (
        <p className={`font-fira text-2xl font-bold ${braceColor}`}>{"{}"}</p>
      ) : (
        <div className="flex flex-row gap-2 justify-center items-center">
          <p className={`font-fira text-xl font-bold ${textColor}`}>"jsonGen":</p>
          <p className={`font-fira text-xl font-bold ${braceColor}`}>{"{}"}</p>
        </div>
      )}
    </a>
  );
}
